import { Module, GetterTree, MutationTree, ActionTree } from "vuex";
import { RootState } from "@/types/state";
import CoControlState from "@/types/controls/co";
import ControlService from "@/services/ControlService";

const namespaced = true;
const controlService = new ControlService();

export const state: CoControlState = {
  control_id: "",
  address_items: [],
  code_items: [],
  name: "",
  fax_number: "",
  federal_id_number: "",
  telephone: "",
  state: "",
  required_login: "",
  promise: null,
};

export const getters: GetterTree<CoControlState, RootState> = {
  getCodeItems: (state) => {
    return state.code_items;
  },
  getDefaultCodeItem: (state) => {
    if(state.code_items.length > 0) {
      return state.code_items[0].code || "";
    } else {
      return "1";
    }
  }
};

export const mutations: MutationTree<CoControlState> = {
  SET_CODE_ITEMS(state, code_items) {
    state.code_items = code_items;
  },
  SET_CONTROL_ID(state, control_id) {
    state.control_id = control_id;
  },
  SET_PROMISE(state, promise) {
    state.promise = promise;
  }
};

export const actions: ActionTree<CoControlState, RootState> = {
  fetchCoControl({ commit, state }) {
    if(state.promise) {
      return state.promise;
    }

    const promise = new Promise((resolve, reject) => {
      if (state.control_id && state.code_items.length > 0) {
        resolve(state);
      } else {
        controlService.getControl("COMPANY", "CO.CONTROL", "CONTROL", "")
          .then((response: any) => {
            if(response.length > 0){
              commit("SET_CODE_ITEMS", response[0].code_items || []);
              commit("SET_CONTROL_ID", response[0].control_id || "");
              resolve(response[0]);
            } else {
              reject("No data returned");
            }
          })
          .catch((error) => {
            reject(error);
          })
          .finally(() => {
            commit("SET_PROMISE", null);
          });
      }
    });

    commit("SET_PROMISE", promise);

    return state.promise;
  },
};

export const coControl: Module<CoControlState, RootState> = {
  namespaced,
  getters,
  state,
  mutations,
  actions,
};
